<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            :src="require('@/assets/images/terra-home/favicon.png')"
            alt="Logo"
            style="max-height: 30px;"
          >
          <h2 class="brand-text text-primary ml-1">
            Terra-Home
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Création de votre compte
        </b-card-title>

        <b>Je suis :</b>

        <b-tabs
          pills
          justified
        >
          <b-tab
            title="Client"
            active
          >
            <validation-observer ref="registerFormClient">
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="validationFormClient"
              >
                <!-- username -->
                <b-form-group
                  label="Nom"
                  label-for="nom"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nom"
                    rules="required"
                  >
                    <b-form-input
                      id="nom"
                      v-model="nom"
                      :state="errors.length > 0 ? false:null"
                      name="register-nom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Prénom"
                  label-for="prenom"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="prenom"
                    rules="required"
                  >
                    <b-form-input
                      id="prenom"
                      v-model="prenom"
                      :state="errors.length > 0 ? false:null"
                      name="register-prenom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false:null"
                      name="register-email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group
                  label="Téléphone"
                  label-for="tek"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tel"
                    rules="required"
                  >
                    <b-form-input
                      id="tel"
                      v-model="tel"
                      class="form-control-merge"
                      name="register-tel"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <b-button
                  variant="primary"
                  block
                  type="submit"
                >
                  Créer mon compte
                </b-button>
              </b-form>
            </validation-observer>
          </b-tab>

          <b-tab
            title="Constructeur"
          >
            <validation-observer ref="registerFormConstructeur">
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="validationFormConstructeur"
              >
                <!-- username -->
                <b-form-group
                  label="Nom"
                  label-for="nom"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nom"
                    rules="required"
                  >
                    <b-form-input
                      id="nom"
                      v-model="nom"
                      :state="errors.length > 0 ? false:null"
                      name="register-nom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Prénom"
                  label-for="prenom"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="prenom"
                    rules="required"
                  >
                    <b-form-input
                      id="prenom"
                      v-model="prenom"
                      :state="errors.length > 0 ? false:null"
                      name="register-prenom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false:null"
                      name="register-email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group
                  label="Téléphone"
                  label-for="tek"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tel"
                    rules="required"
                  >
                    <b-form-input
                      id="tel"
                      v-model="tel"
                      name="register-tel"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Sociéte / Agence"
                  label-for="societe"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Societe / Agence"
                    rules="required"
                  >

                    <b-form-input
                      id="societe"
                      v-model="societe"
                      :state="errors.length > 0 ? false:null"
                      name="register-societe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <b-button
                  variant="primary"
                  block
                  type="submit"
                >
                  Créer mon compte
                </b-button>
              </b-form>
            </validation-observer>
          </b-tab>
        </b-tabs>

        <!-- form -->

        <b-card-text class="text-center mt-2">
          <span>Vous avez déja un compte? </span>
          <b-link :to="{name:'login'}">
            <span>Connectez-vous</span>
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm, BTab, BTabs,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BTab,
    BTabs,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      nom: '',
      prenom: '',
      email: '',
      tel: '',
      societe: null,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationFormClient() {
      this.$refs.registerFormClient.validate().then(success => {
        if (success) {
          this.createCompte('client')
        }
      })
    },
    validationFormConstructeur() {
      this.$refs.registerFormConstructeur.validate().then(success => {
        if (success) {
          this.createCompte('constructeur')
        }
      })
    },
    createCompte(type) {
      const data = {
        nom: this.nom,
        prenom: this.prenom,
        email: this.email,
        tel: this.tel,
      }
      if (type === 'constructeur') {
        data.societe = this.societe
      }

      this.$store.dispatch('auth/new', data)
        .then(() => {
          this.$swal({
            title: 'Votre compte a bien été créé !',
            text: 'Vous allez recevoir un email de confirmation, une fois votre compte validé par un administrateur, vous pourrez vous connecter',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            this.$router.push('/login')
          })
        })
        .catch(e => {
          let title = 'Une erreur s\'est produite !'
          let text = 'Veuillez vous rapprocher de Terra-Home'
          if (e.response.data === 'ACCOUNT_ALREADY_EXIST') {
            title = 'Un compte existe déja'
            text = ''
          }
          this.$swal({
            title,
            text,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
